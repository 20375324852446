.App {
    text-align: center;
    
  }
  
  .logo {
    font-size: 40px;
    color: white;
    position: relative;
  }
  
  .App-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    padding: 10px 20px;
    background-color: #000; /* Assuming a black background as in the screenshot */
    color: #fff;
    z-index: 1;
    width: 100%;
  }
  
  .App-main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    flex: 1;
    background-image:url('/public/DALLE BG.png');
    background-size: cover;
  }
  
  .header-buttons {
    display: flex;
    position: relative;
    margin-right: 2%;
  }
  
  .profile-button {
    /* Styling for buttons */
    background-color: #000000; /* Example button color */
    color: white;
    border: 1px solid white;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .logout-button {
    /* Styling for buttons */
    background-color: #007bff; /* Example button color */
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .profile-button:hover,
  .logout-button:hover {
    /* Hover effect for buttons */
    background-color: #0056b3; /* Darker shade for hover state */
  }
  
  .profile-and-ledger-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow-y: auto; /* Add this line to enable vertical scrolling */

}

.profile {
  background-color: transparent;
  display: flex;
  width: 90%; /* Adjust width as needed */
  /* Remove margin-top and margin-left if not needed */
}

.ledger {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
  overflow-y: auto; /* Add this line to enable vertical scrolling */

}

  
  .verification {
    background-color: black;
    border: 1px solid #fff;
    width: 90%;
    height: 140px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    position: top;

  }
  
  .verification-icon {
    font-size: 40px; /* Adjust icon size */
    margin-right: 20px;
  }
  
  .verification-text {
    align-items: left;
    text-align: center;  
    margin-left: 60px;
  }
  
  
  .icon {
    background-color: rgba(130, 165, 255, 1);
    border: none;
    border-radius: 10px;
    position: left;
    width: 70px;
    height: 70px;
    display: flex;
    margin-top: 0px;
    margin-left: 30px;
    
  }
  
  .profile-picture {
    border: none;
    border-radius: 500px;
    width: 10%;
    height: 10%;
  }
  .contact-button {
    background-color: #000; /* Black background */
    color: #fff; /* White text */
    border: 2px solid #fff; /* White border */
    padding: 10px 35px;
    margin-right: 10px; /* Adds margin to the right of the contact button */
    border-radius: 4px;
    cursor: pointer;
  }
  
  .contact-button:hover {
    background-color: #333; /* Darker background on hover */
    
  }
  
  .login-button {
    background-color: #fff; /* White background */
    color: #000; /* Black text */
    border: 2px solid transparent;
    padding: 10px 35px;
    border-radius: 4px;
    cursor: pointer;
    width: auto;
  }
  
  .login-button:hover {
    background-color: #e6e6e6; /* Light gray background on hover */
  }