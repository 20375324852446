.contact-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10; /* Above other content */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4); /* Semi-transparent background */
}

.contact-modal-content {
  width: 20em;
  background-color: #fff;
  padding: 70px;
  border-radius: 10px; /* Rounded corners for the modal */
  position: relative;
}

.contact-modal-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #aaa;
}

.contact-modal-close:hover,
.contact-modal-close:focus {
  color: black;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.contact-form label,
.contact-form input,
.contact-form .submit-button {
  margin: 10px 0; /* Spacing between form items */
}

.contact-form input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.contact-form .submit-button {
  padding: 10px 20px;
  background-color: #82A5FF; /* Example button color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-form .submit-button:hover {
  background-color: #3068D4; /* Darker shade for hover state */
}
