/* Basic CSS for modal */
.echo-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  
  .echo-modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
  }

  .echo-close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  nav {
    /* ... */
  }
  
  .contact-button,
  .login-button {
    /* Styling for buttons */
    background-color: #007bff; /* Example button color */
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .contact-button:hover,
  .login-button:hover {
    /* Hover effect for buttons */
    background-color: #0056b3; /* Darker shade for hover state */
  }
  
  /* Main content styles */
  .verification-section {
    /* Styles for the verification section */
    display: flex;
    justify-content: space-between;
    /* ... */
  }
  
  /* ... other styles ... */
  
  .images-container {
    position: relative;
    width: 100%; /* Makes the container's width responsive */
    max-width: 1200px; /* You might want to limit the maximum size */
    margin: auto; /* Centers the container */
  }
  
  .phone-image,
  .recent-verification-image {
    width: 100%; /* Makes images responsive */
    max-width: 425px; /* Maximum size for the phone image */
    height: auto; /* Maintains aspect ratio */
    position: absolute; /* Positioning inside their container */
  }
  
  .phone-image {
    top: 70%; /* Adjust based on layout */
    left: 70%; /* Adjust based on layout */
    transform: translate(10%, -10%); /* Centers the image */
  }
  
  .recent-verification-image {
    top: 100%;
    left: 40%;
    transform: translate(-40%, 50%);
    max-width: 100%; /* Adjust the max width for better responsiveness */
  }

.about-section h1 {
    font-size: 60px; /* Adjust this to match the second picture if needed */
    text-align: left;
    margin-left: 1em;
    padding-top: 1em;
    text-align: left;


}

.about-section p {
    font-size: 20px; /* Adjust this to match the second picture if needed */
    width: 45em; /* You might need to adjust the width as well */
    text-align: left;
    margin-left: 4em;
    text-align: left;



}
  
  /* ... other styles ... */
  
  .our-project-section {
    top: 50em;
    position: relative;
    margin: 20px;
    padding: 20px;
    background-color: #fff; /* Adjust if you have a specific background color */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for depth */
    border-radius: 8px; /* Optional: rounds the corners */
    z-index: 2; /* Ensure it's above the background but below overlapping images */
    text-align: left;
  }
  
  .our-project-section h2 {
    color: #000; /* Adjust color to match your design */
    font-size: 30px; /* Adjust font size to match your design */
    margin-bottom: 16px;
  }
  .our-project-section h3 {
    color: #82A5FF; /* Adjust color to match your design */
    font-size: 20px; /* Adjust font size to match your design */
    margin-bottom: 16px;
  }
  
  
  .our-project-section p {
    color: #333; /* Adjust text color to match your design */
    font-size: 18px; /* Adjust font size to match your design */
    line-height: 1.6; /* Adjust line height for readability */
  }
  
  /* ... other styles ... */
  
  /* ... other styles ... */
  
  .our-team-section {
    top: 55em;
    text-align: center;
    margin: 20px;
    position: relative;
  }
  
  .our-team-section h2 {
    font-size: 2em; /* Adjust size as needed */
    color: #000; /* Title color */
    margin-bottom: 0.5em;
  }
  
  /* ... other styles ... */
  
  .team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px; /* Adjust the space between team member blocks as needed */
  }
  
  .team-member {
    width: 150px; /* Adjust width as needed */
    display: flex;
    flex-direction: column;
    align-items: center; /* This ensures that the content is centered horizontally */
    text-align: center;
  }
  
  .member-photo {
    background-color: #ddd; /* Placeholder color for photo */
    width: 100%; /* Full width of the container */
    height: 150px; /* Adjust height as needed */
    border-radius: 4px; /* Optional: for rounded corners */
    /* Remove any padding or margin that could cause misalignment */
  }
  
  .member-name {
    color: #000; /* Adjust link color as needed */
    margin-top: 8px; /* Adjust space between photo and name */
    text-decoration: none; /* Removes underline from links */
    font-size: 1em; /* Adjust font size as needed */
  }
  
  /* ... other styles ... */
  
  /* ... other styles ... */
  
  .social-media-section {
    /* Styles for content sections */
    top: 50em;
    margin: 20px;
    position: relative;
    /* ... */
  }
  
  
  .highlighted-text {
    color: #82A5FF; /* Replace with the color you want */
    font-size: 100px;
    text-align: left;
  }
  
  .App-header1 {
    display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #000; /* Assuming a black background as in the screenshot */
  color: #fff;
  position: absolute; /* Keeps the header fixed at the top */
  top: 0; /* Aligns the header at the very top of the page */
  left: 0; /* Aligns the header to the left of the page */
  width: 100%; /* Ensures the header spans the full width of the viewport */
  z-index: 10000; /* Ensures the header stays */
  }
  
  .logo {
    font-size: 40px;
    color: white;
  }
  
  .header-buttons {
    display: flex;
  }
  
  .contact-button {
    background-color: #000; /* Black background */
    color: #fff; /* White text */
    border: 2px solid #fff; /* White border */
    padding: 10px 10px;
    margin-right: 10px; /* Adds margin to the right of the contact button */
    border-radius: 4px;
    cursor: pointer;
  }
  
  .contact-button:hover {
    background-color: #333; /* Darker background on hover */
  }
  
  .login-button {
    background-color: #fff; /* White background */
    color: #000; /* Black text */
    border: 2px solid transparent;
    padding: 10px 35px;
    border-radius: 4px;
    cursor: pointer;
    width: auto;
  }
  
  .login-button:hover {
    background-color: #e6e6e6; /* Light gray background on hover */
  }
  
  /* Styles for the about section */
  .about-section h1 {
    font-size: 75px;
    text-align: left;

  }
  
  .about-section p {
    font-size: 20px;
    width: 45em;
    text-align: left;

  }
  
  .member-name {
    display: inline-block;
    padding: 6px;
    margin: 2px;
    border: 2px solid #82A5FF; /* Adjust the color as needed */
    border-radius: 5px; /* Optional: for rounded corners */
  }
  
  .footer {
    background-color: #f0f0f0; /* Light grey background */
    text-align: center;
    padding: 20px 0;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  
  .footer img {
    width: 150px; /* Adjust based on your logo size */
  }

  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden; /* Prevents horizontal overflow caused by child elements */

  }
  .container {
    width: 100%;
    max-width: 1200px; /* Adjust as needed */
    margin: 0 auto; /* Centers the container */
  }
  @media screen and (max-width: 768px) {
    .highlighted-text {
      color: hsl(223, 100%, 75%); /* Replace with the color you want */
      font-size: 50px;
      margin-top: 2em;     
      padding-top: 1em; 
      text-align: left;
      width: 100%;
      text-align: center;
    }
    .phone-image,
    .recent-verification-image {
      top: initial;
      left: initial;
      transform: none;
      position: relative; /* Stack images or adjust positioning for smaller screens */
      width: 90%; /* Take up more of the container width */
      margin: 10px auto; /* Center images with auto margins */
    }
    .login-button {
      background-color: #fff; /* White background */
      color: #000; /* Black text */
      border: 2px solid transparent;
      padding: 0.5em;
      border-radius: 4px;
      cursor: pointer;
      width: auto;
      overflow-x: hidden; /* Prevents horizontal overflow */

    }
    .contact-button {
      background-color: #000; /* Black background */
      color: #fff; /* White text */
      border: 2px solid #fff; /* White border */
      padding: 0.5em;
      border-radius: 4px;
      cursor: pointer;
      width: auto;
      overflow-x: hidden; /* Prevents horizontal overflow */

    }
    /* Adjust layout for smaller screens */
    .verification-section,
    .about-section,
    .our-project-section {
      padding-top: 1em;
      text-align: center; /* Center text for smaller screens */
    }
    .echo-modal-content {
      margin: 10% auto; /* Adjust top margin for smaller screens */
      width: 90%; /* Make the modal width smaller on mobile */
    }
    .about-section h1 {
      font-size: 40px; /* Reduced font size for smaller screens */
      margin-left: 1em;
      margin-right: 1em;
      padding-top: 1em;
      text-align: center; /* Centered text for smaller screens */
    }
  
    .about-section p {
      font-size: 16px; /* Reduced font size for smaller screens */
      width: auto; /* Auto width for better fit */
      margin-left: 1em; /* Adjusted margin for smaller screens */
      margin-right: 1em;
      text-align: center; /* Centered text for smaller screens */
    }
  
    .our-project-section {
      top: auto; /* Adjust position for smaller screens */
      text-align: center; /* Centered text for smaller screens */
    }
  
    .our-team-section {
      top: auto; /* Adjust position for smaller screens */
      text-align: center; /* Centered text for smaller screens */
    }
  
    .footer {
      padding: 10px 0; /* Adjusted padding for smaller screens */
    }
    .logo {
      font-size: 1em; /* Adjusted font size for smaller screens */
      margin-left: 0.1em;
      padding-left: 0.1em;
    }
    .container {
      padding: 0 10px; /* Add padding on smaller screens */
    }
  
    html, body {
      margin: 0;
    padding: 0;
      width: 100%;
      overflow-x: hidden; /* Prevents horizontal overflow */
    }
    .header-buttons {
      flex-wrap: wrap; /* Allows buttons to wrap to the next line */
      justify-content: center; /* Centers the buttons if they wrap */
    }
    .App-header1 {
      display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 0em; /* Adjusted padding for smaller screens */
    background-color: #000; /* Assuming a black background as in the screenshot */
    color: #fff;
    position: absolute; /* Keeps the header fixed at the top */
    top: 0; /* Aligns the header at the very top of the page */
    left: 0; /* Aligns the header to the left of the page */
    width: 100%; /* Ensures the header spans the full width of the viewport */
    z-index: 10000; /* Ensures the header stays */
    overflow-x: hidden; /* Prevents horizontal overflow */
    margin-bottom: 2em;
    }
    .contact-button,
  .login-button {
    padding: 0.5em; /* Adjusted padding for smaller screens */
    margin: 0.5em; /* Adds margin around buttons */
    width: auto; /* Allows buttons to adjust width */
  }
  }
  